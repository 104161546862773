.HomePage{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 8px 0;
    overflow: hidden;
}


@media (max-width: 900px) {
    .HomePage{
        flex-direction: column;
        justify-content: flex-start;
        align-items: normal;
    }
}

