.StateSection{
    padding: 4px;
    animation: fade 0.8s ease-in-out;
    animation-delay: 2s;
    animation-fill-mode: backwards;
    min-height: 220px;

}
@keyframes fade {
    from {
        opacity: 0;
        transform: translateY(50px)
    }
    to {
        opacity: 1;
        transform: translateY(0)
    }
}
.StateStats{
    animation: fade 0.8s ease-in-out;
    animation-delay: 0.7s;
    animation-fill-mode: backwards;
}
.StateStats > h5{
    letter-spacing: 1px;
    word-spacing: 1px;
}
.StateStats > h6{
    letter-spacing: 1px;
    font-size: 0.8em;
    margin-top: 4px;
}

.StateStats > header{
    margin-top: 32px;
    display: flex;
    align-items: center;
}
.StateZones{
    margin-top: 26px;
    animation: fade 0.8s ease-in-out;
    animation-delay: 1s;
    animation-fill-mode: backwards;

}

.StateZones > h6{
    letter-spacing: 1px;
}
.StateZones > p{
    letter-spacing: 1px;
    font-size: 0.85em;
    margin-top: 2px;
    margin-bottom: 16px;
}
@media (max-width: 400px) {
    .StateSection{
        width: 97%;
    }
}
@media (max-width: 350px) {

    .StateStats > h5{
        font-size: 1.4em;
        margin-left: 8px;
    }
    .StateStats > h6{
        font-size: 0.75em;
        margin-top: 4px;
        margin-left: 8px;
    }
}