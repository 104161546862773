.Containment{
    padding: 4px;
    box-sizing: border-box;
    width: 500px;
    animation: fade 0.8s ease-in-out;
    animation-delay: 1s;
    animation-fill-mode: backwards;
    order: -1;
    margin-right: 40px;
}

.Containment > h5{
    letter-spacing: 1px;
    word-spacing: 1px;
    display: block;
}

@keyframes fade {
    from {
        opacity: 0;
        transform: translateY(50px)
    }
    to {
        opacity: 1;
        transform: translateY(0)
    }
}

@media (max-width: 900px) {
    .Containment{
        order: -1;
        margin: 8px 0 36px;
        width: auto;
    }
}
@media (max-width: 400px) {
    .StateSection{
        width: 100%;
    }
}
@media (max-width: 350px) {

    .Containment > h5{
        font-size: 1.4em;
    }

}