.Clist {
    margin-top: 18px;
}

.Clist > header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
}
.Clist > header > p{
    font-size: 0.82em;
    word-spacing: 1px;
}
.Clist > p {
    word-spacing: 1px;
    letter-spacing: 1px;
}
.CListStats{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    margin: 22px 0;
    font-size: 0.9em;

}
.CListStats > div{
    flex: 1;
    border-radius: 4px;
    background-color: grey;
}
.CListStats > div:first-of-type{
    background-color: #F9C9C9;
    margin-right: 16px;
    color: #ef2525;
}

.CListStats > div:last-of-type{
    background-color: #9EC2F8;
    color: #1558F3;
}

.CListStats > div > p:last-of-type{
    margin-top: -10px;
    font-weight: bold;
}
.ClistZone {
    font-size: 1.1em;
    margin: 16px 0;
    word-spacing: 1px;
    padding: 8px;
    animation: fade 0.4s ease-in-out;
    animation-delay: 0.2s;
    animation-fill-mode: backwards;
}

@keyframes fade {
    from {
        opacity: 0;
        transform: translateY(50px)
    }
    to {
        opacity: 1;
        transform: translateY(0)
    }
}

@media (max-width: 400px) {
    .Clist > p{
        font-size: 0.92em;
    }

}