.NearbyZones{
    margin-bottom: 12px;
    animation: fade 0.8s ease-in-out;
    animation-delay: 0.5s;
    animation-fill-mode: backwards;
    font-size: 0.9em;
    margin-top: 18px;
}
.NearbyZones > h6{
    word-spacing: 1px;
    letter-spacing: 1px;
    margin-top: 24px;
    margin-bottom: 24px;
}
.NearbyZones > header {
    margin: 16px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.NearbyZones > form{
    padding: 4px;
}
@keyframes fade {
    from {opacity: 0; transform: translateY(50px)}
    to {opacity: 1; transform: translateY(0)}
}