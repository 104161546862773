.StatsCard{
    text-align: center;
    padding: 2px;
    border-radius: 4px;
    width: 100px;
    margin-right: 8px;
    font-size: 0.9em;
    max-width: 25%;
}

.StatsCard > p:first-of-type{
    margin: 4px;
}

.StatsCard:last-of-type{
    margin-right: 0;
}

.StatsCard > p:nth-child(2){
    margin: 4px;
    font-weight: bold;
}
.StatsCard > p:last-of-type{
    margin: 2px 4px 4px 0;
}
@media (max-width: 480px) {
    .StatsCard{
        width: 80px;
        font-size: 0.85em;
    }

}

@media (max-width: 380px) {
    .StatsCard{
        width: 70px;
        font-size: 0.75em;
    }

}

@media (max-width: 350px) {
    .StatsCard{
        width: 60px;
        font-size: 0.7em;
    }

}